import React from "react";

const Privacy = () => {
  return (
    <>
      <h1 className="text-center">Privacy Policy</h1>
      <hr />
      <p></p>
      <p>
        <b>laxmimatka.net</b>
      </p>
      <p>
        With the laxmimatka app, you can get all Game result results, such as
        Kalyan game, Game Mumbai, and Game free games. Our Game Guessing Forum
        also provides the best Game tips.
      </p>
      <p></p>
      <p>
        <b>Privacy Policy</b>
      </p>
      <p>
        laxmimatka developed the Game result Free Tips app as an Ad Supported
        application. laxmimatka is providing this service free of charge and as
        is.
      </p>
      <p></p>
      <p>
        The purpose of this page is to inform visitors about how I collect, use,
        and disclose Personal Information if they decide to use my service.
      </p>
      <p></p>
      <p>
        You consent to my collecting and using information as described in this
        policy if you choose to use my Service. The Personal Information that I
        collect is used for improving the Service and providing it to you. You
        can rest assured that I will not use or share your information in any
        way other than what is described here.
      </p>
      <p></p>
      <p>
        Unless defined otherwise in this Privacy Policy, all terms in this
        document have the same meaning as those in our Terms and Conditions,
        which are accessible at deeeplaxmi free tips app.
      </p>
      <p></p>
      <p>
        <b>Information Collection and Use</b>
      </p>
      <p></p>
      <p>
        In order to provide you with a better experience while using our
        Service, I may ask for some personally identifiable data from you. You
        must agree to the information I request to be retained on your device. I
        do not collect any of this information.
      </p>
      <p></p>
      <p>You may be identified by third party services used in the app.</p>
      <p></p>
      <p>
        This app uses third-party service providers whose privacy policies are
        linked
      </p>
      <p></p>
      <p>
        <b>Google Play Services</b>
      </p>
      <p>
        <b>Log Data</b>
      </p>
      <p></p>
      <p>
        My Service collects log data on your phone whenever there is an error in
        the app (through third-party products). As part of this Log Data, your
        device may provide the following information: your IP (Internet
        Protocol) address, the model of your device, the operating system
        version, your application configuration, the time and date you used my
        Service, and other statistics.
      </p>
      <p></p>
      <p>
        <b>Cookies</b>
      </p>
      <p></p>
      <p>
        As anonymous unique identifiers, cookies contain a small amount of data.
        Websites send these to your browser and store them on your device.
      </p>
      <p></p>
      <p>
        There are no explicit cookies used by this Service. The app may,
        however, use code and libraries from third parties that use 'cookies' to
        collect information and improve its services. Depending on your
        preferences, you can accept or refuse these cookies. The Service may not
        function properly if you reject our cookies.
      </p>
      <p></p>
      <p>
        <b>Service Providers</b>
      </p>
      <p></p>
      <p>
        <b>There are several reasons why I may work with third parties:</b>
      </p>
      <p></p>
      <p>As a means of providing our service;</p>
      <p>Assist us in providing the Service;</p>
      <p>As part of the Service; or</p>
      <p>To help us analyze our Service's usage.</p>
      <p>
        The third parties listed above have access to your Personal Information.
        Our tasks are performed by them. It is strictly prohibited for them to
        disclose or use the information in any other way.
      </p>
      <p></p>
      <p>
        <b>Security</b>
      </p>
      <p></p>
      <p>
        Keeping your Personal Information safe is important to me, so we use
        commercially acceptable methods to protect it. It should be noted that
        no method of electronic transmission or storage, whether over the
        Internet or electronic disk, can be guaranteed as 100% secure and
        reliable.
      </p>
      <p></p>
      <p>
        <b>Links to Other Sites</b>
      </p>
      <p></p>
      <p>
        There may be links to other sites on this Service. A third-party link
        will redirect you to its site if you click on it. Please note that I do
        not operate these external websites. It is therefore highly recommended
        that you review the Privacy Policies of these websites. Sites and
        services provided by third parties are beyond my control, and I do not
        accept responsibility for their content or privacy policies.
      </p>
      <p></p>
      <p>
        <b>Childrenâ€™s Privacy</b>
      </p>
      <p></p>
      <p>
        Children under the age of 13 are not eligible to use these Services.
        Under no circumstances do I knowingly collect personal information from
        children under the age of 13. We immediately delete personal information
        provided by children under 13 when we discover it. As a parent or
        guardian, if you discover that your child has provided us with personal
        information, please let me know so that I can take the necessary steps.
      </p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p>
        <b>Changes to This Privacy Policy</b>
      </p>
      <p></p>
      <p>
        Our Privacy Policy may be updated from time to time. Please review this
        page periodically for any changes. Any changes to this Privacy Policy
        will be posted on this page. Posting these changes on this page will
        make them effective immediately.
      </p>
      <p></p>
      <p>
        <b>Contact Us</b>
      </p>
      <p></p>
      <p>
        I would appreciate it if you could contact me with any questions or
        suggestions regarding my Privacy Policy.
      </p>
      <p></p>
      <p></p>
      <p></p>
    </>
  );
};

export default Privacy;
