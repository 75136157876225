import React, { useEffect, useState } from "react";
import BG from "../assets/card.webp";
import G1 from "../assets/1.webp";
import G2 from "../assets/2.webp";
import G3 from "../assets/3.webp";
import G4 from "../assets/4.webp";
import G5 from "../assets/5.webp";
import G6 from "../assets/6.webp";
import G7 from "../assets/7.webp";

const GamePlay = () => {
  const [apiData, setApiData] = useState([]);

  const data = [G1, G2, G3, G4, G5, G6, G7];

  useEffect(() => {
    const getData = async () => {
      var api = await fetch("https://game.laxmimatka.net/api/rate");
      api = await api.json();
      setApiData(api.data.result);
    };
    getData();
  }, []);

  return (
    <>
      <div className="xl:px-40 lg:px-14 xs:px-5 my-10 relative" id="chart">
        <p className="xs:text-xl lg:text-2xl py-10 font-medium text-center">
          GAME PLAY RATES
        </p>

        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-10">
          {apiData.map((item, index) => {
            return (
              <div className="relative flex gap-5 flex-col items-center justify-center text-3xl uppercase font-medium p-5 px-3 text-white rounded-md" key={index}  data-aos="flip-right">
                  <img
                    src={BG}
                    className="absolute block max-h-full w-full -z-50 rounded-md"
                    alt=""
                  />
                  <div className="bg-[#690228] rounded-full border border-slate-500 flex justify-center items-center w-32 h-32 p-5" >
                    <img src={data[index]} alt=""/>
                  </div>
                  <div className="bg-orange-400 rounded-md text-accent font-medium text-2xl p-3 px-5  w-full homebtn">
                    <p className="text-center text-sm">
                      {item[0]} {item[1].slice(0, 2)} KA {item[1].slice(3, 10)}
                    </p>
                  </div>
                </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GamePlay;
