import React, { useEffect } from "react";
import { useState } from "react";
import BG from "../assets/card.webp";

const Results = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      var api = await fetch("https://game.laxmimatka.net/api/games");
      api = await api.json();
      setApiData(api.data.result);
    };

    getData();
  }, []);
  return (
    <>

      <div className="xl:px-40 mb-10 lg:px-14 xs:px-5" id="result">
        <p className="lg:text-4xl xs:text-2xl font-bold text-center my-10">
          Laxmi Matka Result
        </p>

        <div className="grid xl:grid-cols-3  xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 ">
          {apiData.map((item , index) => {
            return (
              <div key={index} data-aos="zoom-out-down">
                <div className="relative  flex gap-10 flex-col items-center justify-center text-3xl uppercase font-medium p-5 px-3 text-white rounded-md">
                  <img
                    src={BG}
                    className="absolute block max-h-full w-full -z-50 rounded-md"
                    alt=""
                  />
                  <p className="text-orange-500  text-xl text-center">{item.game_name}</p>
                  <div className="text-4xl flex justify-between gap-10">
                    <p>{item.result.slice(0,3)}</p>
                    <p>{item.result.slice(4,6)}</p>
                    <p>{item.result.slice(7,10)}</p>
                  </div>
                  <div className="bg-orange-400 rounded-md text-accent font-medium text-xl p-3 px-5 flex justify-between w-full homebtn">
                    <p className="">{item.open_time}</p>
                    <p className="">{item.close_time}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        </div>
    </>
  );
};

export default Results;
