import React from "react";
import MOBILE from "../assets/mobile.webp";
import BG from "../assets/HomeBG.webp";
import { FaDownload, FaGooglePlay } from "react-icons/fa";

const Home = () => {
  return (
    <>
      <div
        className="relative xs:py-10 grid md:grid-cols-2 xs:grid-cols-1 lg:grid-cols-2 xs:h-full mt-16 min-h-screen"
        id="home"
      >
        <img
          src={BG}
          alt=""
          className="absolute xs:object-cover lg:object-center -z-50 xs:h-full block w-screen"
        />
        <div className="flex items-center justify-center">
          <img src={MOBILE} alt="" className="w-9/12"  data-aos="flip-right" />
        </div>
        <div className="flex flex-col lg:gap-10 xs:gap-5 items-start justify-center text-white ">
          <p className="xs:text-3xl md:text-xl lg:text-5xl xs:text-center lg:text-start lg:text-[3rem] w-11/12 font-medium leading-tight">
            Download Best{" "}
            <span className="headSpan  text-accentOrange">Online Matka</span>{" "}
            App!
          </p>
          <p className="lg:text-xl  md:text-lg xs:text-xl xs:text-center lg:text-start">
            PLAY UPTO LACS DAILY WITH Laxmi Matka.
          </p>
          <div className="flex lg:flex-row xs:flex-col  xs:items-center w-full xs:gap-5 lg:gap-10">
            <a href="https://game.laxmimatka.net/downloadApk">
              <button className="lg:text-xl text-white bg-[#FD9223] font-medium  p-4 px-6 homebtn">
                <FaDownload /> Download APK
              </button>
            </a>
            <a href="" target="_blank" rel="noreferrer">
              <button className="lg:text-xl text-white bg-[#FD9223] font-medium p-4 px-6 homebtn">
                <FaGooglePlay /> Google Play Store
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
