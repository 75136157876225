import React from "react";
import { useState, useEffect } from "react";
import { FaBars, FaLock, FaPhone, FaTimes, FaUser, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/logo.webp";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [contact, setContact] = useState(false)
  const [setting, setSetting] = useState("");

  var whatsapp = setting && setting.whatsapp_number;
  whatsapp = whatsapp.slice(1)

  useEffect(() => {
    const getContact = async () => {
      await fetch("https://game.laxmimatka.net/api/setting")
        .then((res) => res.json())
        .then((e) => setSetting(e.data.result));
    };
    getContact();
  }, []);
  return (
    <>
      <div className="flex fixed top-0 z-50 w-full justify-between items-center xl:px-40 xs:px-10 lg:px-14 py-5 bg-[#690228] text-white">
        <Link to="/">
          <img src={logo} alt="" className="lg:w-32 xs:w-20" />
        </Link>

        <div
          className={`${
            !nav ? "hidden" : "block"
          } xs:absolute xs:top-20 bg-[#690228] w-full xs:px-10 xs:pb-7 lg:pb-0 lg:px-0 xs:right-0 lg:top-0 lg:relative lg:flex justify-end items-center`}
        >
          <ul className="flex xs:gap-3 lg:gap-0 items-center lg:flex-row xs:flex-col h-full nav-top">
            <a href="#home" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">HOME</li>
            </a>
            <a href="#result" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">RESULTS</li>
            </a>
            <a href="#about" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">ABOUT US</li>
            </a>
            <a href="#table" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">TIME TABLE</li>
            </a>
            <a href="#chart" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">RATE CHART</li>
            </a>
            <a href="#play" rel="noreferrer" onClick={() => setNav(false)}>
              <li className="cursor-pointer">HOW TO PLAY</li>
            </a>
          </ul>
        </div>
        <div className="flex gap-5 ml-8">
          <a
            href="https://game.laxmimatka.net/"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" bg-white text-[#690228] border-2 hover:border-white hover:bg-transparent hover:text-white rounded">
              <FaLock />
              Login
            </button>
          </a>
          <button className="bg-white p-2 rounded xs:block lg:hidden ">
            {!nav ? (
              <FaBars
                className="text-2xl text-black bg-white "
                onClick={() => setNav(!nav)}
              />
            ) : (
              <FaTimes
                className="text-2xl text-black bg-white "
                onClick={() => setNav(!nav)}
              />
            )}
          </button>
        </div>

        <div className="fixed bottom-5 right-5 rounded-full">
          <div
            className={`${
              contact
                ? "flex flex-col justify-center items-center gap-3 my-3"
                : "hidden"
            }`}
          >
            <div className="bg-blue-500 p-3 rounded-full">
              <a
                href={`tel:${setting.mobile_number}`}
              >
                <FaPhone className="text-white text-xl" />
              </a>
            </div>
            <div className="bg-green-600 p-3 rounded-full">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hello%2C%20I'm%20Contact%20You%20From%20Your%20Website%20Deep%20Laxmi`}
              >
                <FaWhatsapp className="text-white text-xl" />
              </a>
            </div>
          </div>
          <div className="bg-orange-500 p-3 rounded-full">
            <FaUser
              className="text-accent text-3xl"
              onClick={() => {
                setContact(!contact);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
