import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

const ViewChart = () => {
  let { slug } = useParams();

  const [apiData, setApiData] = useState([]);
  const [chartName, setChartName] = useState("");

  useEffect(() => {
    var data = {
      // eslint-disable-next-line
      game_id: slug,
    };

    const getData = async () => {
      var api = await fetch("https://game.laxmimatka.net/api/chart", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      api = await api.json();
      setChartName(api.data.game_name);
      setApiData(api.data.result);
    };
    getData();
  }, [slug]);

  return (
    <>
      <Link to="/">
        <BsFillArrowLeftCircleFill className="fixed text-4xl top-5 left-5 text-[#690228]" />
      </Link>
      <div className="lg:px-20 p-10 py-10">
        <p className="font-medium text-2xl mb-5  text-center">
          Laxmi Matka
          <span className="uppercase"> {chartName} </span>
          Result Chart
        </p>
        <div className="grid xl:grid-cols-7  xs:grid-cols-2 md:grid-cols-4 gap-y-5 text-black">
          {apiData.map((item, index) => {
            return (
              <div className="border-2 border-black" key={index}>
                <div className="text-center">
                  <p className="text-xl font-medium">{item.date.slice(0, 4)}</p>
                  <p className="text-xl font-medium">
                    {item.date.slice(4, 20)}
                  </p>
                </div>
                <div className={`border-t-2 border-black grid grid-cols-3`}>
                  <div
                    className={`table-cell text-center`}
                    style={{ color: item.color }}
                  >
                    <font className={`right-font`}>{item.left}</font>
                  </div>
                  <p
                    className={`h-full flex items-center justify-center text-2xl font-medium border-x border-black text-center text-[${item.color}]`}
                    style={{ color: item.color }}
                  >
                    {item.center}
                  </p>
                  <div
                    className={`table-cell text-center`}
                    style={{ color: item.color }}
                  >
                    <font className={`right-font`}>{item.right}</font>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ViewChart;
