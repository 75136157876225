import React, { useEffect, useState } from "react";
import { FaChartLine } from "react-icons/fa";
import { Link } from "react-router-dom";
import table from "../assets/tablebg.webp";

const Table = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      var api = await fetch("https://game.laxmimatka.net/api/games");
      api = await api.json();
      setApiData(api.data.result);
    };

    getData();
  }, []);

  return (
    <>
      <div className="lg:px-40 xs:px-5 py-10 relative" id="table">
        <img
          src={table}
          alt=""
          className="absolute left-0 right-0 object-cover -z-50 h-full block w-screen"
        />
        <p className="text-white lg:text-4xl xl:text-5xl xs:text-3xl lg:font-medium text-center my-14 ">
          Game Time Table
        </p>
        <div className=" relative  shadow-md mb-10">
          <table className="w-full lg:text-lg text-left text-gray-500 dark:text-gray-400">
            <thead className="lg:text-lg text-accentOrange uppercase bg-[#690228] text-center">
              <tr className="grid grid-cols-4">
                <th scope="col" className="py-3 md:px-6">
                  MARKET
                </th>
                <th scope="col" className="py-3 md:px-6">
                  OPEN
                </th>
                <th scope="col" className="py-3 md:px-6">
                  CLOSE
                </th>
                <th scope="col" className="py-3 md:px-6">
                  RESULTS
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {apiData.map((item, index) => {
                return (
                  <tr
                    className=" grid grid-cols-4 bg-white border-b"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="text-black md:py-4 md:px-6  font-medium "
                    >
                      {item.game_name}
                    </th>
                    <td className="text-black md:py-4 md:px-6">
                      {item.open_time}
                    </td>
                    <td className="text-black md:py-4 md:px-6">
                      {item.close_time}
                    </td>
                    <td className="text-black md:py-4 md:px-2 whitespace-nowrap">
                      <Link
                        to={`/view_chart/${item.id}`}
                        className="hover:bg-accent  md:px-3 py-1 rounded hover:border-accent hover:text-black font-medium text-sm  text-black flex items-center justify-center"
                      >
                        <p className="hidden md:block">View Chart</p>
                        <FaChartLine className="md:hidden text-3xl " />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Table;
