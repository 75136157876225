import React from "react";
import PLAY from "../assets/play.webp";
import {
  FaChevronCircleRight,
  FaDownload,
  FaGamepad,
  FaUnlock,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Play = () => {
  return (
    <>
      <div className="xl:px-40 lg:px-14 xs:px-5 py-10 relative" id="play" >
        <img
          src={PLAY}
          alt=""
          className="absolute left-0 ring-0 object-cover -z-50 h-full block w-screen"
        />
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 pt-20">
          <div className="text-accentOrange flex flex-col gap-10 justify-center items-center text-xl text-center h-full">
            <p className=" lg:text-4xl xl:text-6xl xs:text-4xl lg:font-medium">
              How To Play
            </p>
            <p className="lg:text-xl xl:text-2xl xs:text-lg xs:text-justify lg:text-center font-light w-4/5">
              A casino is a facility for certain types of gambling. Casinos are
              often built combined with hotels, resorts.
            </p>
          </div>
          <div className="grid md:grid-cols-2 xs:grid-cols-1  gap-x-20 justify-center  text-white" data-aos="zoom-in">
            <div className="relative flex gap-5 flex-col items-center justify-start  p-5 px-3 text-white rounded-md group">
              <div className="bg-[#690228] hover:bg-yellow-500 rounded-full  flex justify-center items-center w-32 h-32 group-hover:bg-yellow-500">
                <FaDownload className="text-6xl" />
              </div>
              <p className="group-hover:text-yellow-500 text-center xs:text-xl lg:text-xl xl:text-2xl">
                1. Download App
              </p>
            </div>
            <div className="relative flex gap-5 flex-col items-center justify-start  p-5 px-3 text-white rounded-md group">
              <div className="bg-[#690228] hover:bg-yellow-500 rounded-full  flex justify-center items-center w-32 h-32 group-hover:bg-yellow-500">
                <FaChevronCircleRight className="text-6xl" />
              </div>
              <p className="group-hover:text-yellow-500 text-center xs:text-xl lg:text-xl  xl:text-2xl">
                2. Click On Sign In
              </p>
            </div>
            <div className="relative flex gap-5 flex-col items-center justify-start w-full text-white rounded-md group">
              <div className="bg-[#690228] hover:bg-yellow-500 rounded-full  flex justify-center items-center w-32 h-32 group-hover:bg-yellow-500">
                <FaUnlock className="text-6xl" />
              </div>
              <p className="group-hover:text-yellow-500 text-center xs:text-xl lg:text-lg  xl:text-xl">
                3. Enter Mobile Number & Password
              </p>
            </div>
            <div className="xs:mt-10 md:mt-0 relative flex gap-5 flex-col items-center justify-start w-full text-white rounded-md group">
              <div className="bg-[#690228] hover:bg-yellow-500 rounded-full  flex justify-center items-center w-32 h-32 group-hover:bg-yellow-500">
                <FaGamepad className="text-6xl" />
              </div>
              <p className="group-hover:text-yellow-500 text-center xs:text-xl lg:text-lg  xl:text-xl">
                4. Select Game & Play The Game
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#690228]  p-5">
        <p className="text-white text-center">
          Copyright <span className="font-medium text-blue-500">Laxmi Matka </span>- All
          Right Reserved |{" "}
          <Link to="/privacy-policy" className="font-medium text-blue-500">Privacy Policy</Link>
        </p>
      </div>
    </>
  );
};

export default Play;
