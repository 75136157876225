import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import GamePlay from "./components/GamePlay";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Play from "./components/Play";
import Privacy from "./components/Privacy";
import Results from "./components/Results";
import Table from "./components/Table";
import ViewChart from "./components/ViewChart";

import Aos from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, [])

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Results />
              <About />
              <Table />
              <GamePlay />
              <Play />
            </>
          }
        />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/view_chart/:slug" element={<ViewChart />} />
      </Routes>
    </>
  );
};

export default App;
