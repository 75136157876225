import React from "react";
import BG1 from "../assets/About.webp";
import image from "../assets/About1.webp"

const About = () => {
  return (
    <>

      <div className="xl:px-40 xs:px-5 lg:px-14 relative pt-10"  id="about">
        <img
          src={BG1}
          alt=""
          className="absolute left-0 right-0 object-cover -z-50 h-full block w-screen"

        />
        <p className="text-white text-4xl font-medium text-center my-14">
          ABOUT US
        </p>
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-8 md:grid-cols-2">
          <div className="flex items-center justify-center">
            <img src={image} alt="" className="w-3/4" data-aos="flip-left" />
          </div>
          <div className="text-accentOrange lg:text-sm xl:text-sm xs:text- text-justify" data-aos="flip-left">
            <p className="mb-10">
              Let your guessing win you massive amount through Laxmi Matka App.
              Join our platform for expert advice and Matka tips. Get the most
              trusted results daily. Play Online Matka through Laxmi Matka is
              the most preferred and platform for online Matka play. It provides
              the most updated Satta Matka play results. Our app is one stop for
              all the updated live results for various Matka market. Use RR
              Starline to get the live updated results and earn huge profit.
              Earn Real Money through your tricks and updates.
            </p>
            <p className="pb-10">
              We provide you with fastest online results of every bazar in the
              matka industry. We provide fastest results of SRIDEVI DAY, TIME
              BAZAR, MADUR DAY, RUDRAKSH DAY, MILAN DAY, RAJDHANI DAY, SUPREME
              DAY, KALYAN DAY, SRIDEVI NIGHT, SUPREME NIGHT, MILAN NIGHT,
              RAJDHANI NIGHT And MAIN RATAN.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
